$maxwidth: 992px;

.showroom {
  height: calc(100vh - 47px);
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  overflow: hidden;

  @media screen and (max-width: $maxwidth) {
    height: calc(55vh - 4px);
  }

  img {
    width: 100%;
    max-width : 1400px;
  }
}

.perspectives {
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  
  img {
    width: 75px;
    &.selected {
      border: 2px solid #0773da6e;
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  @media screen and (max-width: $maxwidth) {
    border-bottom: 5px solid #343a40;
    position: relative;
    z-index: 1;
  }
}
.sidebar-sticky {
  position: sticky;
  top: 46px;
  height: calc(100vh - 46px);
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  @media screen and (max-width: $maxwidth) {
    height: calc(45vh - 48px);
  }
}

.modal-layout {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(255,255,255,.9);
  z-index: 999;
  padding: 20px;
}
.history {

  flex-wrap: wrap;
  
  .history-item-wrapper {

    .remove {
      position: absolute;
      top: 5px;
      right: 10px
    }
  
    .history-item {
      width: 180px;
      height: 100px;
      background-size: cover;
      border-radius: 6px;
      border: 3px solid #333;
      cursor: pointer;
      margin-right: 3px;
      margin-bottom: 3px;
      transition: all 0.2s linear;
      background-position: 0px -3px;

      &:hover,  &.active {
        background-position: 0px 0px;
        box-shadow: inset 0 0px 5px #333;
      }
      &.active {
        width: 190px;
        height: 110px;
        border-color: #4CAF50;
      }
    }
  }
}

.btn-save {
  position: absolute;
  bottom: 20px;
  right: 20px
}

@media screen and (max-width: $maxwidth) {
  .modelName.lead {
    font-size: 1.1rem;
  }
}